
import Portfolio1 from '../assets/portfolio-1.png'


export const projectList = [
    {
    name: "Personal Website",
    image: Portfolio1,
    skills:"asdfas",
    link: "intro"
    },
    {
        name: "DonkeyType",
        image: Portfolio1,
        skills:"asdfas",
        link: "proj1"
    },
    {
        name: "Tetris",
        image: Portfolio1,
        skills:"asdfas",
        link: "proj2"
    },
    {
        name: "File Compressor",
        image: Portfolio1,
        skills:"asdfas",
        link: "proj3"
    },
    {
        name: "Mortgage Loan Analyzer",
        image: Portfolio1,
        skills:"asdfas",
        link: "proj4"
    },
    // {
    //     name: "safafs",
    //     image: Portfolio1,
    //     skills:"asdfas",
    //     link: ""
    // },



]