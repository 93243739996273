import React from "react";
import './footer.css'

const Footer = () => {
    return (
    <footer className="footer">
    </footer>
    )
}

export default Footer